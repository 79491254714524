import * as React from "react"
import Layout from '../component/layout'
import bottomImage from '../images/image-asset.webp'
import {StaticImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby';

// markup
const IndexPage = () => {
  const ExternalIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
    </svg>
  }

  return (
    <Layout title={'Home | YouYou'}
            keywords={['LGBTQ', 'singapore', 'support']}>
      <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-5'>
        {/* row: 1 col on small screen, 2 cols on bigger screens */}
        <div className='col-span-1 md:col-span-2 text-center font-sylexiad'>
          <StaticImage src='../images/YouYou-Front-Page-Welcome_1500px.webp' 
                        alt='We welcome YOU for being YOU'
                        className='z-0 rounded-md shadow-lg' />
        </div>
        
        {/* row */}
        <div className='col-span-1 md:col-span-1'>
          <p className='subtitle'>Affirmation, Inclusiveness, Diversity</p>
          <h1 className='title'>About YouYou</h1>
          <p className='text'>YouYou is a community committed to the safe space of spiritual minorities and LGBTQIA+. We're here for those who left or were left behind in religious or heteronomative institutions. We advocate interfaith action and understanding, and celebrate diversity of all backgrounds and orientations. We walk together in our own unique paths, and we love to walk with you.</p>
          <br />
          <a className='external-link' href='https://heckinunicorn.com/pages/statement-love-support-lgbt-members-community-in-singapore'>{'Statement of love & support for all members of our LGBT+ community in Singapore '}<ExternalIcon /></a>
        </div>

        {/* row */}
        <div className='col-span-1 md:col-span-2 text-center justify-center align-center'>
          <Link to='/about-us'><button className="general-button">Learn More</button></Link>
        </div>

        {/* row */}
        <div className='col-span-1 md:col-span-2 text-center rounded-md'>
          <StaticImage src='../images/You-You-Front-Page2-Special_1500px.webp' 
                        alt='you are special JUST the way you are'
                        className='rounded-md shadow-lg'/>
        </div>

        {/* row */}
        <div className='col-span-1 md:col-span-1 text-center'>
          <div className='text-left inline-block'>
            <p className='subtitle'>Statement of Purpose</p>
            <h2 className='title'>Our Principles</h2>
          </div>
        </div>
        <div className='col-span-1 md:col-span-1'>
          <ul className='statement-list ml-4 md:ml-0'>
            <li>To <i><strong>affirm</strong></i> each person regardless of background, such as spiritual belief and orientation.</li>
            <li>To <i><strong>embrace</strong></i> queer diversity and religious pluralism.</li>
            <li>To <i><strong>provide</strong></i> a safe space for the LGBTQIA+, spiritual and non-faith minorities.</li>
            <li>To <i><strong>discuss</strong></i> honestly the benefits and harms of institutions and communities we experienced.</li>
            <li>To <i><strong>uplift</strong></i> every member with their orientation, choice of spirituality, and life journey.</li>
          </ul>
        </div>

      </div>

      <br />

      <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-3 gap-4 justify-center items-stretch m-5'>
        {/* row */}
        <div className='col-span-1 text-center'>
          <StaticImage src='../images/YouYou-Icons1-confidentiality.webp' alt='Confidentiality' className='rounded-md shadow-lg'/>
          <br /><br />
          <strong className='image-caption-title'>Confidentiality</strong>
          <p className='text mt-2'>Narratives and experiences are kept secure here.</p>
        </div>
        <div className='col-span-1 text-center'>
          <StaticImage src='../images/YouYou-Icons2-respect.webp' alt='Respect' className='rounded-md shadow-lg'/>
          <br /><br />
          <strong className='image-caption-title'>Respect</strong>
          <p className='text mt-2'>Respect each other as you would wish others to respect you.</p>
        </div>
        <div className='col-span-1 text-center'>
          <StaticImage src='../images/YouYou-Icons3-growth.webp' alt='Growth' className='rounded-md shadow-lg'/>
          <br /><br />
          <strong className='image-caption-title'>Growth</strong>
          <p className='text mt-2'>One for all, all for one.<br />We are all in this together.<br />No member is left behind.</p>
        </div>

        {/* row */}
        <div className='grid-cols-1 md:col-span-3 text-center'>
          <p className='text'>More about our <Link to='/about-us#safe-space-guidelines' className='underline'>Safe Space guidelines here!</Link></p>
        </div>
      </div>

      <br />

      <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-5'>
        {/* row */}
        <div className='col-span-1 text-center relative'>
          <div className='inline-block text-center md:text-left'>
            <h2 className='title text-center md:text-left'>We are here for...</h2>
            <ul className='statement-list list-inside leading-loose ml-2 text-left md:text-left'>
              <li>The broken hearted</li>
              <li>The down trodden</li>
              <li>The broken hearted</li>
              <li>The down trodden</li>
              <li>The guilt ridden</li>
              <li>The outcast</li>
              <li>The bullied</li>
              <li>The shamed</li>
              <li>The non-conforming</li>
              <li>The jaded + numb</li>
              <li>The condemned</li>
              <li>The ex-believer</li>
              <li>The non-religious</li>
              <li>The disillusioned</li>
              <li>The unchurched</li>
              <li>The conflicted</li>
              <li>The afflicted</li>
              <li>The excommunicated</li>
              <li>The questioning + unsure</li>
              <li>The seeker</li>
            </ul>
          </div>
          <br />
          <Link to='/contact'><button className='general-button absolute left-5'>Visit Us</button></Link>
          <Link to='/meetings'><button className='general-button absolute right-5'>See when we meet</button></Link>
          <br /><br />
        </div>

        <div className='col-span-1'>
          <StaticImage src='../images/YouYou_HQ_Rainbow.webp' alt='Lights for Pink Dot' className='rounded-md shadow-lg'/>
        </div>

        {/* row */}
        <div className='col-span-1 mt-5 text-center'>
          <StaticImage src='../images/YouYou_interfaith_whiteboard.webp' alt={`[words written on card] imagine a space where you could find wisdom in all faiths, like Buddhism, Christianity, Wicca... and in science, humanism and art. imagine a space where all geners --- women, non-binary, and men were equal and LGBTQ+ people fully welcome & loved. Imagine a space which didn't tell you what to believe but helped you discover your own spirituality. imagine a space with no dogma or creeds, but with tolerance & love. YouYou`} className='rounded-md shadow-lg'/>
        </div>
        <div className='col-span-1 mt-5 text-center'>
          <div className='inline-block text-left'>
            <h2 className='title'>Our YouYou sessions aim to:</h2>
            <ul className='statement-list list-outside leading-loose ml-4 text-left md:text-left'>
                <li>Be individually beneficial every time</li>
                <li>Have members feel secure and free</li>
                <li>Develop a continued genuine interest in each other's lives.</li>
            </ul>
            </div>
        </div>
        <br />
        
        {/* row */}
        <div className='col-span-1 md:col-span-2 text-center relative flex items-center justify-center'>
          <div className='z-10 mt-10 md:mt-15 mb-80 mx-auto my-auto flex-auto'>
            <p className='text text-sm md:text-2xl text-white'>Every issue can be discussed in a civil and respectful manner.<br />Every session can be beneficial to each individual.<br />All emotions can be managed.<br />All expressions and views come from a valid human being.<br /><br />YOUYOU ETHOS</p>
          </div>
          {/* <StaticImage src='../images/image-asset.webp' alt='background image of people talking outside' className='absolute mx-auto my-auto top-0 left-0 bottom-0 z-0 rounded-md flex-auto'/> */}
          <img src={bottomImage} alt='people talking outside' className='shadow-lg absolute max-h-full mx-auto right-0 top-0 left-0 bottom-0 z-0 rounded-md flex-auto' loading='lazy'/>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
